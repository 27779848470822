import GATSBY_COMPILED_MDX from "/home/amb/c/website/src/posts/backticks-in-zsh-are-fantastic.mdx";
import React from "react";
import {graphql} from "gatsby";
import Header from "../components/header";
import "./blog-post.css";
function Template({data, children}) {
  const {mdx} = data;
  const {frontmatter} = mdx;
  return React.createElement(React.Fragment, null, React.createElement(Header, {
    plaid: true,
    withIcons: false
  }, React.createElement("h1", null, frontmatter.title), React.createElement("h3", {
    className: "blog-post-date"
  }, new Date(frontmatter.date).toDateString())), React.createElement("div", {
    className: "blog-post-container"
  }, React.createElement("div", {
    className: "blog-post"
  }, React.createElement("div", {
    className: "blog-post-content"
  }, children))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Template, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date
        path
        title
      }
    }
  }
`;
