/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    hr: "hr",
    h2: "h2",
    div: "div",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Taken from the ", React.createElement(_components.a, {
    href: "http://www.aosabook.org/en/bdb.html"
  }, "Berkeley DB"), " section of\n", React.createElement(_components.a, {
    href: "http://www.aosabook.org/en/index.html"
  }, "The Architecture of Open-Source\nApplications"), ", which breaks up its\ndiscussion of the application's specific architecture with general lessons\nand aphorisms. Read the whole thing, it's great."), "\n", React.createElement(_components.p, null, "There's a grimness to these that I find utterly charming: ", React.createElement(_components.a, {
    href: "#design-lesson-3"
  }, "lesson\n3"), " warns \"[s]oftware architecture degrades in direct\nproportion to the number of changes made to the software: bug fixes corrode the\nlayering and new features stress design.\" It's an approach that treats software\ndesign in general and object orientation in specific (OO as in \"code that goes\n'not my job, you figure it out'\", not as in \"code that is organized with\nclasses\") not like a matter of artistic composition so much as intellectual\nsanitation. It's a dirty world out there: wash your ass."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "design-lesson-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-1",
    "aria-label": "design lesson 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 1"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "It is vital for any complex software package's testing and maintenance that the\nsoftware be designed and built as a cooperating set of modules with\nwell-defined API boundaries. The boundaries can (and should!) shift as needs\ndictate, but they always need to be there. The existence of those boundaries\nprevents the software from becoming an unmaintainable pile of spaghetti. Butler\nLampson once said that all problems in computer science can be solved by\nanother level of indirection. More to the point, when asked what it meant for\nsomething to be object-oriented, Lampson said it meant being able to have\nmultiple implementations behind an API. The Berkeley DB design and\nimplementation embody this approach of permitting multiple implementations\nbehind a common interface, providing an object-oriented look and feel, even\nthough the library is written in C."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-2",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-2",
    "aria-label": "design lesson 2 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 2"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "A software design is simply one of several ways to force yourself to think\nthrough the entire problem before attempting to solve it. Skilled programmers\nuse different techniques to this end: some write a first version and throw it\naway, some write extensive manual pages or design documents, others fill out a\ncode template where every requirement is identified and assigned to a specific\nfunction or comment. For example, in Berkeley DB, we created a complete set of\nUnix-style manual pages for the access methods and underlying components before\nwriting any code. Regardless of the technique used, it's difficult to think\nclearly about program architecture after code debugging begins, not to mention\nthat large architectural changes often waste previous debugging effort.\nSoftware architecture requires a different mind set from debugging code, and\nthe architecture you have when you begin debugging is usually the architecture\nyou'll deliver in that release."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-3",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-3",
    "aria-label": "design lesson 3 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 3"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Software architecture does not age gracefully. Software architecture degrades\nin direct proportion to the number of changes made to the software: bug fixes\ncorrode the layering and new features stress design. Deciding when the software\narchitecture has degraded sufficiently that you should re-design or re-write a\nmodule is a hard decision. On one hand, as the architecture degrades,\nmaintenance and development become more difficult and at the end of that path\nis a legacy piece of software maintainable only by having an army of\nbrute-force testers for every release, because nobody understands how the\nsoftware works inside. On the other hand, users will bitterly complain over the\ninstability and incompatibilities that result from fundamental changes. As a\nsoftware architect, your only guarantee is that someone will be angry with you\nno matter which path you choose."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-4",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-4",
    "aria-label": "design lesson 4 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 4"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "It doesn't matter how you name your variables, methods, functions, or what\ncomments or code style you use; that is, there are a large number of formats\nand styles that are \"good enough.\" What does matter, and matters very much, is\nthat naming and style be consistent. Skilled programmers derive a tremendous\namount of information from code format and object naming. You should view\nnaming and style inconsistencies as some programmers investing time and effort\nto lie to the other programmers, and vice versa. Failing to follow house coding\nconventions is a firing offense."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-5",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-5",
    "aria-label": "design lesson 5 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 5"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Software architects must choose their upgrade battles carefully: users will\naccept minor changes to upgrade to new releases (if you guarantee compile-time\nerrors, that is, obvious failures until the upgrade is complete; upgrade\nchanges should never fail in subtle ways). But to make truly fundamental\nchanges, you must admit it's a new code base and requires a port of your user\nbase. Obviously, new code bases and application ports are not cheap in time or\nresources, but neither is angering your user base by telling them a huge\noverhaul is really a minor upgrade."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-6",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-6",
    "aria-label": "design lesson 6 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 6"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "In library design, respect for the namespace is vital. Programmers who use your\nlibrary should not need to memorize dozens of reserved names for functions,\nconstants, structures, and global variables to avoid naming collisions between\nan application and the library."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-7",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-7",
    "aria-label": "design lesson 7 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 7"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Before we wrote a shared-memory linked-list package, Berkeley DB engineers\nhand-coded a variety of different data structures in shared memory, and these\nimplementations were fragile and difficult to debug. The shared-memory list\npackage, modeled after the BSD list package (queue.h), replaced all of those\nefforts. Once it was debugged, we never had to debug another shared memory\nlinked-list problem. This illustrates three important design principles: First,\nif you have functionality that appears more than once, write the shared\nfunctions and use them, because the mere existence of two copies of any\nspecific functionality in your code guarantees that one of them is incorrectly\nimplemented. Second, when you develop a set of general purpose routines, write\na test suite for the set of routines, so you can debug them in isolation.\nThird, the harder code is to write, the more important for it to be separately\nwritten and maintained; it's almost impossible to keep surrounding code from\ninfecting and corroding a piece of code."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-8",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-8",
    "aria-label": "design lesson 8 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 8"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Write-ahead logging is another example of providing encapsulation and layering,\neven when the functionality is never going to be useful to another piece of\nsoftware: after all, how many programs care about LSNs in the cache?\nRegardless, the discipline is useful and makes the software easier to maintain,\ntest, debug and extend."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-9",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-9",
    "aria-label": "design lesson 9 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 9"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Berkeley DB's choice to use page-level locking was made for good reasons, but\nwe've found that choice to be problematic at times. Page-level locking limits\nthe concurrency of the application as one thread of control modifying a record\non a database page will prevent other threads of control from modifying other\nrecords on the same page, while record-level locks permit such concurrency as\nlong as the two threads of control are not modifying the same record.\nPage-level locking enhances stability as it limits the number of recovery paths\nthat are possible (a page is always in one of a couple of states during\nrecovery, as opposed to the infinite number of possible states a page might be\nin if multiple records are being added and deleted to a page). As Berkeley DB\nwas intended for use as an embedded system where no database administrator\nwould be available to fix things should there be corruption, we chose stability\nover increased concurrency."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-10",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-10",
    "aria-label": "design lesson 10 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 10"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Berkeley DB's general-purpose design was well rewarded when we added concurrent\ndata store functionality. Initially Berkeley DB provided only two modes of\noperation: either you ran without any write concurrency or with full\ntransaction support. Transaction support carries a certain degree of complexity\nfor the developer and we found some applications wanted improved concurrency\nwithout the overhead of full transactional support. To provide this feature, we\nadded support for API-level locking that allows concurrency, while guaranteeing\nno deadlocks. This required a new and different lock mode to work in the\npresence of cursors. Rather than adding special purpose code to the lock\nmanager, we were able to create an alternate lock matrix that supported only\nthe lock modes necessary for the API-level locking. Thus, simply by configuring\nthe lock manager differently, we were able provide the locking support we\nneeded. (Sadly, it was not as easy to change the access methods; there are\nstill significant parts of the access method code to handle this special mode\nof concurrent access.)"), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-11",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-11",
    "aria-label": "design lesson 11 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 11"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "When you find an architectural problem you don't want to fix \"right now\" and\nthat you're inclined to just let go, remember that being nibbled to death by\nducks will kill you just as surely as being trampled by elephants. Don't be too\nhesitant to change entire frameworks to improve software structure, and when\nyou make the changes, don't make a partial change with the idea that you'll\nclean up later—do it all and then move forward. As has been often repeated, \"If\nyou don't have the time to do it right now, you won't find the time to do it\nlater.\" And while you're changing the framework, write the test structure as\nwell."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-12",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-12",
    "aria-label": "design lesson 12 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 12"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Mpool and Log use internal handle methods to facilitate write-ahead logging,\nand in some cases, the method declaration is longer than the code it runs,\nsince the code is often comparing two integral values and nothing more. Why\nbother with such insignificant methods, just to maintain consistent layering?\nBecause if your code is not so object-oriented as to make your teeth hurt, it\nis not object-oriented enough. Every piece of code should do a small number of\nthings and there should be a high-level design encouraging programmers to build\nfunctionality out of smaller chunks of functionality, and so on. If there's\nanything we have learned about software development in the past few decades, it\nis that our ability to build and maintain significant pieces of software is\nfragile. Building and maintaining significant pieces of software is difficult\nand error-prone, and as the software architect, you must do everything that you\ncan, as early as you can, as often as you can, to maximize the information\nconveyed in the structure of your software."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-13",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-13",
    "aria-label": "design lesson 13 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 13"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "There is rarely such thing as an unimportant bug. Sure, there's a typo now and\nthen, but usually a bug implies somebody didn't fully understand what they were\ndoing and implemented the wrong thing. When you fix a bug, don't look for the\nsymptom: look for the underlying cause, the misunderstanding, if you will,\nbecause that leads to a better understanding of the program's architecture as\nwell as revealing fundamental underlying flaws in the design itself."), "\n"), "\n", React.createElement(_components.h2, {
    id: "design-lesson-14",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#design-lesson-14",
    "aria-label": "design lesson 14 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Design Lesson 14"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Database recovery is a complex topic, difficult to write and harder to debug\nbecause recovery simply shouldn't happen all that often. In his Turing Award\nLecture, Edsger Dijkstra argued that programming was inherently difficult and\nthe beginning of wisdom is to admit we are unequal to the task. Our goal as\narchitects and programmers is to use the tools at our disposal: design, problem\ndecomposition, review, testing, naming and style conventions, and other good\nhabits, to constrain programming problems to problems we can solve."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
