/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div",
    blockquote: "blockquote",
    code: "code",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.theestablishment.co/2016/09/29/the-dangerous-myth-of-media-objectivity/"
  }, "This"), "\nis a timely and important article, and it drove me absolutely crazy. Before I\nquote anything, I think I need to do some conceptual prep work. The problem, you\nsee, is that the article suffers from a fatal fault—in calling out a false\nnotion of objectivity, it leaves unquestioned its right to be framed as\n\"objectivity\". Look, I know I might sound ridiculous, but goddamn it, ideas\nmatter. It's not so much a problem because that false objectivity is bad per se\n(though it is); it's a problem because a better alternative exists and I think\ngetting it right is really, really important."), "\n", React.createElement(_components.h2, {
    id: "science-is-not-what-but-how",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#science-is-not-what-but-how",
    "aria-label": "science is not what but how permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Science is not what, but how"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "\"The first principle is that you must not fool yourself–and you are the easiest person\nto fool.\""), "\n", React.createElement(_components.p, null, "–Richard Feynman"), "\n"), "\n", React.createElement(_components.p, null, "Human brains have all sorts of shortcuts for finding patterns. It's our greatest talent,\nand it's what's behind the best of us. Here are some ways to conceive of various shortcuts\nfor finding patterns: jumping to a conclusion; conceptual leaps, both the creative\nparallel kind and the brilliant insight kind; optical illusions; artistic license;\nintuition; suspicions; logical fallacies; madness."), "\n", React.createElement(_components.p, null, "Basically every social interaction we have, every use of language, is running on hardware\nthat, when you get down to it, is just doing a bunch of sloppy pattern matching[^1]. And\nwe're ridiculously good at it! It has literally taken us to ", React.createElement(_components.a, {
    href: "http://joshworth.com/dev/pixelspace/pixelspace_solarsystem.html"
  }, "the\nmoon"), ". But if jumping to\nconclusions is fundamental to being an alive human, it means we're never going to not be\nwrong about some stuff. You, me, Einstein, Pedro Martinez, Aristotle, Lupita Nyong'o:\neveryone who ever has or will live is wrong about a ton of shit."), "\n", React.createElement(_components.p, null, "This is almost all you need to acquire a deep understanding of science. The other part is\na way out: using statistics and measurements of the actual world to verify\nstuff. Human perception isn't perfect, but it's still pretty good, and the\nphysical universe makes sense. You take your flawed conceptions as a starting\npoint, and you see which ones you can try to disprove. I say \"try\", because a\nlot of times, you can't even do that: the world is full of questions that have\na single true, reality-based answer that there's no way to find out. Got to\nhope there's Wikipedia in Heaven. But sometimes you CAN check. So you do."), "\n", React.createElement(_components.p, null, "Here's the last catch: you can't really prove anything is true, but sometimes, when the\nfacts cooperate, you can prove things false. So you try to prove everything you believe to\nbe true to be false. Occasionally, to your chagrin, you will be successful. And for\ncenturies, this has been the method of human progress: shuffling painfully towards the\ntruth by discarding convincing bullshit one piece at a time."), "\n", React.createElement(_components.p, null, "That's the whole trick. That's how we got spaceships and vaccines. Until you go measure\nthe real world, everybody's words are just words, and, as previously discussed, we are all\nwrong about a lot of things. Shit, we didn't even need to bring up intentional lying or\nwillful ignorance: even if everyone is trying their very best, people alone can't be\nrelied on for truth. No folk tradition managed to invent antibiotics, you know?"), "\n", React.createElement(_components.h2, {
    id: "what-that-article-means-when-it-says-objectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-that-article-means-when-it-says-objectivity",
    "aria-label": "what that article means when it says objectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What that article means when it says \"objectivity\""), "\n", React.createElement(_components.p, null, "Defining \"objectivity\" in terms of empirical truth, the following paragraph\nbecomes absurd:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Is it the media’s responsibility to cover groups or persons who purposefully use hateful\nand provocative speech as a means to gain attention? In what way should this coverage\nmanifest? And, perhaps most importantly—at what point are journalists obligated to\nrepudiate notions of objectivity for the sake of humanity and morality?"), "\n"), "\n", React.createElement(_components.p, null, "Humanity and morality are, in general, best served by dilligent, unassuming\nrespect for empirical truth. Furthermore, whether a thing is true or false has\nlittle to do with whether it's good or bad[^2], and vice versa."), "\n", React.createElement(_components.p, null, "The most frustrating part of writing this is that I haven't found a way not to\ncome down too harshly. The article is talking about the same problem I am, and\nin many ways it's doing quite a good job of it. While it doesn't properly name\nthe concept it targets, it pins it down precisely through a series of incisive\nand well-sourced takedowns of media uncritically airing people who are\ndemonstrably in the wrong:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "The Milo profile was far from the first time that an outlet has, I’d argue, favored an\nobscure notion of objectivity over the protection of human rights and civil liberties. In\nMay, USA Today ", React.createElement(_components.a, {
    href: "http://www.usatoday.com/story/opinion/2016/05/02/boycott-target-american-family-association-editorials-debates/83848878/"
  }, "printed"), " an op-ed written by the president of the American Family\nAssociation (AFA), Tim Wildmon, urging readers to boycott the discount retailer Target due\nto their inclusive bathroom ", React.createElement(_components.a, {
    href: "https://corporate.target.com/article/2016/04/target-stands-inclusivity#sf45842864"
  }, "policy"), ".\nUSA Today neglected to inform its readers that AFA is\n", React.createElement(_components.a, {
    href: "https://www.splcenter.org/fighting-hate/extremist-files/group/american-family-association"
  }, "an anti-LGBT hate group"), ", while also providing a space for Wildmon to perpetuate the\n“bathroom predator” myth. Not only has this virulent lie been ", React.createElement(_components.a, {
    href: "http://mediamatters.org/research/2016/05/05/comprehensive-guide-debunked-bathroom-predator-myth/210200"
  }, "disproven"), ", its infectious\nreach has had a documented ", React.createElement(_components.a, {
    href: "http://www.advocate.com/transgender/2016/7/13/survey-shows-how-trans-bathroom-predator-myth-hurts-real-people"
  }, "impact"), " on the psychological and physical safety of the\ntransgender community."), "\n"), "\n", React.createElement(_components.p, null, "The problem is that that \"obscure notion of objectivity\" is neither obscure nor,\nI would argue, objectivity. I suspect, if asked to define \"media objectivity\", a\nlot of Americans would sketch out a concept similar to this: an objective media\ndoes not try to shape events, it merely reports on them. I suspect FAR more\nstrongly that this is how the American media itself sees its mission. If the\npitfall to be avoided is a thumb on the scales, then you're at the mercy of the\nexisting terms of debate; which is at the mercy of what humans believe; which,\nc'mon, we've been over that already. Like ", React.createElement(_components.a, {
    href: "https://www.gutenberg.org/files/5500/5500-h/5500-h.htm"
  }, "400 years\nago"), " already. But for\nall the \"obscure notion\" shade it's trying to throw, the article doesn't\nchallenge that as the framework for judging \"objectivity\". And as long as that's\nin place, journalists either have to err on the side of not being to critical or\nthey need to get rid of \"objectivity\" as the primary standard. And that's\nhogwash."), "\n", React.createElement(_components.h2, {
    id: "why-thats-a-problem",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-thats-a-problem",
    "aria-label": "why thats a problem permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why that's a problem"), "\n", React.createElement(_components.p, null, "That definition needs to be demoted to the second concern: the primary metric by which the\nmedia's objectivity should be measured is integrity with objective reality as best we can\nmeasure it. Often that means statistics. It means fact-checking is not a \"nice-to-have\",\nit is fundamental to the whole operation. Really, it means doing some fucking reporting."), "\n", React.createElement(_components.p, null, "The article quotes ", React.createElement(_components.a, {
    href: "https://thesocietypages.org/socimages/2015/04/09/racial-bias-and-media-coverage-of-violent-crime/"
  }, "Lisa\nWade"), ",\na sociology professor at Occidental:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "“Each time we see a black person on TV who is linked with a violent crime or portrayed\nas a criminal, the neurons in our brain that link blackness with criminality fire. The\nmore often a link is triggered, the stronger it becomes. Disproportionate reporting . . .\nmake the neural links in our brain—its actual physical structure—reflect the racism\ninherent in the reporting itself.”"), "\n"), "\n", React.createElement(_components.p, null, "The argument, which I find quite compelling, is that repeating socially-backed but\ndamaging, false, or repugnant rhetoric, ESPECIALLY uncritically, reinforces it in the\naudience's minds, corroding truth and/or the common good. But that logic seems awfully\nopen to extension: repeating this implicit notion that objective balance in a conflict is\nprimarily determined by the social force of one or another \"side\", rather than by its\nverifiable truth[^3] literally builds that link in\nreaders' minds. People use words the same way the folks around them do: for the sake of\nthe people around us, America has to step up at using them in service of truth."), "\n", React.createElement(_components.p, null, "[^1]:"), "\n", React.createElement(_components.p, null, "European philosophy has spent lifetimes failing to get around this fact,\nwhich is why it is so ", React.createElement(_components.a, {
    href: "http://www.fullbooks.com/The-Ethics.html"
  }, "beautiful"), "\nand so\n", React.createElement(_components.a, {
    href: "https://tractatus-online.appspot.com/Tractatus/jonathan/index.html"
  }, "tedious"), "."), "\n", React.createElement(_components.p, null, "[^2]:"), "\n", React.createElement(_components.p, null, "The most pernicious form this takes is \"we can't change ", React.createElement(_components.code, null, "$THING"), " because\nthat's just the way things are\". Beyond the surface-level problem of\nsquelching analysis of ", React.createElement(_components.code, null, "$THING"), " in good/bad terms because of a tautological\nassertion of its truth value, it nihilistically sweeps away any reasonable\nquestion of the upside risk of attempting to change ", React.createElement(_components.code, null, "$THING"), " ", React.createElement(_components.em, null, "and"), " it's\nalmost always wrong, historically speaking."), "\n", React.createElement(_components.p, null, "[^3]: It is always both, of course, for we are awash in a sea of opinions."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
