/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    h2: "h2",
    a: "a",
    div: "div",
    pre: "pre",
    ol: "ol",
    li: "li",
    em: "em",
    img: "img",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I thought it would be cool to get my computer to automatically run ", React.createElement(_components.code, null, "brew update"), " every so often in the background and email me if anything went wrong. I\nthought it would be pretty simple! It was not, in fact, prety simple: I ran\ninto a bunch of super frustrating errors, usually because I was taking some\naspect of my normal terminal environment for granted (the ", React.createElement(_components.code, null, "$PATH"), " variable that\ntells the shell where to look to find commands; email protocols and\nauthentication; ssh authentication; error handling in the shell; etc etc etc)."), "\n", React.createElement(_components.p, null, "But I learned a bunch in the process! So kick back, pour yourself a drink, and\nlearn about ", React.createElement(_components.code, null, "cron"), ", the old-school unix tool you can use to run programs behind\nyour own back."), "\n", React.createElement(_components.h2, {
    id: "what-is-a-cron-and-how-does-it-cron",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-cron-and-how-does-it-cron",
    "aria-label": "what is a cron and how does it cron permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is a ", React.createElement(_components.code, null, "cron"), " and how does it... cron?"), "\n", React.createElement(_components.p, null, "Cron is an old unix program used to run commands repeatedly on a schedule.\nThere are two main parts to the cron system: there is the cron daemon, a\nprogram that (once set up) is constantly running in the background; and there\nis the crontab file, which keeps the schedule of programs to run [^1]. Once every minute\non the minute, assuming your computer is awake and running, the cron daemon (the system\nautomatically launches ", React.createElement(_components.code, null, "cron"), " after you save your first valid crontab file, and on boot\nafterwards) evaluates each line of all of the relevant installed crontab files, running\nany commands whose schedules match on the current minute. Any output to ", React.createElement(_components.code, null, "$STDERR"), " is\nassumed to be an error and is mailed to you: ", React.createElement(_components.code, null, "cron"), "'s default mailing strategy is a local\n\"mailbox\" originally used for pre-internet user-to-user messages within a multi-user\nsystem, but if you're fancy with the google, it's pretty manageable to teach it to use\nsomething like gmail instead."), "\n", React.createElement(_components.h2, {
    id: "the-crontab-file",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-crontab-file",
    "aria-label": "the crontab file permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The ", React.createElement(_components.code, null, "crontab"), " file"), "\n", React.createElement(_components.p, null, "You edit the crontab file with the command ", React.createElement(_components.code, null, "crontab -e"), ", regardless of where\nyou are in the filesystem. You need to do some config to use this; more on that\nlater."), "\n", React.createElement(_components.p, null, "Each line either contains a shell variable definition, in which case ", React.createElement(_components.code, null, "cron"), "\nupdates its environment accordingly[^2], or a\nscheduled command. Scheduled commands are structured like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "* * * * *  /absolute/path/of/command/to/execute\n│ │ │ │ │\n│ │ │ │ └─── day of week (0 - 7) (0 to 6 are Sunday to Saturday, or use names; 7 is Sunday, the same as 0)\n│ │ │ └──────── month (1 - 12)\n│ │ └───────────── day of month (1 - 31)\n│ └────────────────── hour (0 - 23)\n└─────────────────────── min (0 - 59)\n")), "\n", React.createElement(_components.p, null, "For any element of a schedule (hours, minutes, etc), you can use:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "*  matches every value\n-  defines a range, like `9-17` for every hour from 9AM to 5PM\n,  separates multiple individual values, such as `MON,WED,FRI`\n")), "\n", React.createElement(_components.p, null, "So, for example ", React.createElement(_components.code, null, "0 9-17 * * 1-5"), " matches each hour from 9AM to 5PM on the hour,\neach weekday, with no restrictions by month or day of month."), "\n", React.createElement(_components.h2, {
    id: "editing-crontab-takes-special-config-because-life-is-suffering",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#editing-crontab-takes-special-config-because-life-is-suffering",
    "aria-label": "editing crontab takes special config because life is suffering permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Editing ", React.createElement(_components.code, null, "crontab"), " takes special config, because life is suffering"), "\n", React.createElement(_components.p, null, "So ", React.createElement(_components.code, null, "crontab -e"), " opens the file with whatever your have set to ", React.createElement(_components.code, null, "$EDITOR"), " in your\nshell session. I use vim, which is secretly a problem: the ", React.createElement(_components.code, null, "crontab"), " program\nsets some rules about how you save the file, and vim's defaults work\ndifferently."), "\n", React.createElement(_components.p, null, "Specifically, when vim writes changes to a file, it first saves them as a\nbackup file, then overwrites the original. This helps vim be more confident it\nwon't corrupt data if it crashes partway through writing, but ", React.createElement(_components.code, null, "crontab"), " won't\nlet you write to anything but the file itself, in-place. To get around this,\nadd to ", React.createElement(_components.code, null, "~/.vimrc"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-vim"
  }, "autocmd filetype crontab setlocal nobackup nowritebackup\n")), "\n", React.createElement(_components.h2, {
    id: "cron-doesnt-have-a-lot-of-things-i-took-for-granted-about-the-shell-environment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cron-doesnt-have-a-lot-of-things-i-took-for-granted-about-the-shell-environment",
    "aria-label": "cron doesnt have a lot of things i took for granted about the shell environment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.code, null, "cron"), " doesn't have a lot of things I took for granted about the shell environment"), "\n", React.createElement(_components.p, null, "This isn't that bad to deal with once you get your head around it, but it took\nme a while to realize just how much implicit environment I rely on when working\nin a terminal. The main one is the ", React.createElement(_components.code, null, "$PATH"), " variable: for every command that\nisn't built-in shell syntax, odds are good you'll need to prepend the ", React.createElement(_components.code, null, "/bin/"), "\nor ", React.createElement(_components.code, null, "/usr/local/bin/"), " or ", React.createElement(_components.code, null, "/usr/sbin/"), " or what have you. If you don't know the\nfull path of some command you use a lot, for example, ", React.createElement(_components.code, null, "git"), ", pop open a\nterminal and run ", React.createElement(_components.code, null, "which git"), "."), "\n", React.createElement(_components.p, null, "My first instinct was to make one of my ", React.createElement(_components.code, null, "crontab"), " file's first lines something like"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "PATH=/Users/ambirdsall/bin:/usr/local/bin:/usr/bin:/bin:/I/dunno/maybe/sbin:/other/things/I'm/used/to/also\n")), "\n", React.createElement(_components.p, null, "If my crontab environment has access to all the same stuff I use everyday in my\nterminal, then writing a cron command is just like writing any old terminal\ncommand. But I think that's the wrong approach, for two reasons:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Anything more complicated than a one-liner (and even many of those), you're\nbetter off just saving in a shell script"), "\n", React.createElement(_components.li, null, "If you succeed, you've just hidden from yourself how things work under the\nhood AND made it easier for part of your terminal-based life to get out of\nsync."), "\n"), "\n", React.createElement(_components.p, null, "If you feel a little lost when working with the full absolute paths of programs\nand would like a better handle on why different programs live in ", React.createElement(_components.code, null, "/bin"), " vs\n'/sbin' vs ", React.createElement(_components.code, null, "/usr/sbin"), " vs ", React.createElement(_components.code, null, "/usr/local/bin"), "—or even why some programs live in\nmore than one of those places—just run ", React.createElement(_components.code, null, "man hier"), " in your terminal and give it\na quick read."), "\n", React.createElement(_components.h2, {
    id: "lets-put-almost-all-of-it-together",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lets-put-almost-all-of-it-together",
    "aria-label": "lets put almost all of it together permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Let's put almost all of it together"), "\n", React.createElement(_components.p, null, "Disclaimer: we're skipping over some hoops I had to jump through to send actual\nemails to my gmail account from the command line (google it) and the way\nthornier issue of how to authenticate an SSH connection with github (which is\nwhere ", React.createElement(_components.code, null, "brew"), " searches for updates) in a bare-bones scripting environment\n(google \"askpass\" or go ", React.createElement(_components.em, null, "really"), " HAM and learn to use ", React.createElement(_components.code, null, "expect"), "). So:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "0 * * * * /usr/local/bin/brew update\n")), "\n", React.createElement(_components.p, null, "will run ", React.createElement(_components.code, null, "brew update"), " every hour on the hour, every single day, with zero\nconscious effort on my part. Awesome! Awesome."), "\n", React.createElement(_components.p, null, "Wait, this ", React.createElement(_components.em, null, "is"), " actually awesome, right?"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "../../dumb-cron-error-email.png",
    alt: "A dumb cron error email"
  })), "\n", React.createElement(_components.p, null, "Goddamn it."), "\n", React.createElement(_components.h2, {
    id: "okay-lets-just-go-through-one-of-the-dumb-problems",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#okay-lets-just-go-through-one-of-the-dumb-problems",
    "aria-label": "okay lets just go through one of the dumb problems permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Okay, let's just go through ", React.createElement(_components.em, null, "one"), " of the dumb problems"), "\n", React.createElement(_components.p, null, "Here's what was going on:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If everything is up to date, ", React.createElement(_components.code, null, "brew"), " exits with a heads-up to ", React.createElement(_components.code, null, "$STDERR")), "\n", React.createElement(_components.li, null, "Seeing a message in the error stream, ", React.createElement(_components.code, null, "cron"), " emailed me"), "\n", React.createElement(_components.li, null, "I don't want email alerts every hour that things are still up-to-date"), "\n"), "\n", React.createElement(_components.p, null, "So:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "/usr/local/bin/brew update 2>&1 > /dev/null | grep -v 'up-to-date' >&2\n")), "\n", React.createElement(_components.p, null, "First, a quick note: I use ", React.createElement(_components.code, null, "zsh"), ", not ", React.createElement(_components.code, null, "bash"), ", and there are a few differences\nin how the two shells handle redirection, so if you use ", React.createElement(_components.code, null, "bash"), ", you might\nneed to make a few changes to get it working properly\n[^3]. Let's break this down:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "2>&1 > /dev/null\n")), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "2>&1"), " redirects ", React.createElement(_components.code, null, "$STDERR"), " to ", React.createElement(_components.code, null, "$STDOUT"), ". The ", React.createElement(_components.code, null, "> /dev/null"), " redirects\n", React.createElement(_components.code, null, "$STDOUT"), " to the Pit of Despair. If you think this seems like it will redirect\nEVERYTHING to ", React.createElement(_components.code, null, "/dev/null"), ", leaving you nothing useful to work with, you think\nlike I do. But it doesn't! Whatever redirections you specify don't take effect\nuntil the next pipe (or the end of the pipeline if there is no next pipe). It\nmakes sense: this system lets you redirect a bunch of things\n[^4] to each\nothers' old handles at the same time without having to worry too much about\noverwriting important data streams because of accidental collisions along the\nway."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "grep -v 'up-to-date'\n")), "\n", React.createElement(_components.p, null, "So it's the contents of ", React.createElement(_components.code, null, "$STDERR"), " alone going through that pipe. Nice. ", React.createElement(_components.code, null, "grep"), "'s\n", React.createElement(_components.code, null, "-v"), " flag reverses the pattern, meaning lines that DON'T contain 'up-to-date'\nare printed. Effectively, this filters out the 'up-to-date' error while letting\nother errors pass through."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, ">&2\n")), "\n", React.createElement(_components.p, null, "The last token there, ", React.createElement(_components.code, null, ">&2"), ", redirects this filtered error stream from ", React.createElement(_components.code, null, "$STDOUT"), "\nback to ", React.createElement(_components.code, null, "$STDERR"), "; if anything else goes wrong, ", React.createElement(_components.code, null, "cron"), " will email as it should,\nbut it won't spam my inbox with nonsense just because I'm already good."), "\n", React.createElement(_components.h3, {
    id: "nice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nice",
    "aria-label": "nice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "NICE."), "\n", React.createElement(_components.p, null, "[^1]:"), "\n", React.createElement(_components.p, null, "Depending on your operating system, there might be both system-wide\ncrontabs and user-specific crontab files; I'm only going to discuss systems\nwith a single crontab file here."), "\n", React.createElement(_components.p, null, "[^2]:"), "\n", React.createElement(_components.p, null, "Setting ", React.createElement(_components.code, null, "$SHELL"), ", ", React.createElement(_components.code, null, "$PATH"), ", and ", React.createElement(_components.code, null, "$MAILTO"), " correctly is quite important, and\nworth some googling if you have questions."), "\n", React.createElement(_components.p, null, "[^3]:"), "\n", React.createElement(_components.p, null, "If you don't know what shell you're using, it's probably ", React.createElement(_components.code, null, "bash"), ", but you\ncan check by running ", React.createElement(_components.code, null, "echo $SHELL"), "."), "\n", React.createElement(_components.p, null, "[^4]:"), "\n", React.createElement(_components.p, null, "There's no rule that says ", React.createElement(_components.code, null, "$STDIN"), ", ", React.createElement(_components.code, null, "$STDOUT"), ", and ", React.createElement(_components.code, null, "$STDERR"), " are the only\n", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/File_descriptor"
  }, "file descriptors"), " your\nprocess can have open, after all."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
