/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    em: "em",
    h2: "h2",
    a: "a",
    div: "div",
    pre: "pre",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Suppose you want to deal with some dates while building a website. You don't want to roll your own input parsing, so you use ", React.createElement(_components.code, null, "new Date(inputDateString)"), "; and you want to output datestrings in a format that's clear, readable[^1], and (while we're making a wish list) sorts the same both lexically and chronologically. That is: you want ", React.createElement(_components.code, null, "YYYY-MM-DD"), ". Surely ", React.createElement(_components.em, null, "that"), " is built in, right?"), "\n", React.createElement(_components.h2, {
    id: "wrong",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wrong",
    "aria-label": "wrong permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "wrong"), "\n", React.createElement(_components.p, null, "Oh. Wait, really? Ugh, fine. Just ", React.createElement(_components.code, null, "npm install date-fns"), " or ", React.createElement(_components.code, null, "dayjs"), " or ", React.createElement(_components.code, null, "moment"), " or"), "\n", React.createElement(_components.h2, {
    id: "youre-seriously-going-to-make-every-user-download-a-whole-library-just-to-create-one1-common-date-format-with-no-time-zone-logic",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#youre-seriously-going-to-make-every-user-download-a-whole-library-just-to-create-one1-common-date-format-with-no-time-zone-logic",
    "aria-label": "youre seriously going to make every user download a whole library just to create one1 common date format with no time zone logic permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "you're seriously going to make every user download a whole library just to create one(1) common date format with no time zone logic."), "\n", React.createElement(_components.p, null, "FINE. Fine. No."), "\n", React.createElement(_components.p, null, "At first, I was tempted to cast the date to a predictable, standardized format and then reach for ~good~ old-fashioned string manipulation:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const datestamp = date => new Date(date).toISOString().slice(0, 10)\n")), "\n", React.createElement(_components.p, null, "But verifying the correctness of that function entails"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "remembering/inferring/guessing that ", React.createElement(_components.code, null, "Date.prototype.toISOString"), " returns an ISO-8601-formatted date;"), "\n", React.createElement(_components.li, null, "remembering that ISO 8601 dates start ", React.createElement(_components.code, null, "YYYY-MM-DD"), ";"), "\n", React.createElement(_components.li, null, "remembering that the ISO 8601 format zero-pads single-digit month and day fields; and"), "\n", React.createElement(_components.li, null, "walking through a date string counting characters on your fingers like a neanderthal."), "\n"), "\n", React.createElement(_components.p, null, "Dear reader, you deserve better than that. You deserve a built-in ", React.createElement(_components.code, null, "strftime"), "-style formatting utility! But since you can't have that, here's a reasonably robust, flexible pattern you can use to extract named, semantically-meaningful parts of the date you can slep into whatever template string you want:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const datestamp = date => {\n  const { year, month, day } = new Intl.DateTimeFormat(undefined, {\n    year: \"numeric\",\n    month: \"2-digit\",\n    day: \"2-digit\",\n  })\n    .formatToParts(new Date(date))\n    .filter(part => part.type !== \"literal\")\n    .reduce((acc, part) => {\n      acc[part.type] = part.value\n      return acc\n    }, {})\n\n  return `${year}-${month}-${day}`\n}\n")), "\n", React.createElement(_components.p, null, "[^1]: What's more, you want people the world over to be able to unambiguously parse your output as ", React.createElement(_components.em, null, "the same"), " date, even if happens to be before the 13th day of its month."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
